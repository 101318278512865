import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const basicTemplate = props => {
  const { pageContext } = props
  const { data } = pageContext
  console.log(data);

  return (
    <Layout>
      <SEO title="Books" />
      <h1>{data.name}</h1>
      <a target="_blank" href={data.amazon_link}>Amazon Link</a>
    </Layout>
  )
}
export default basicTemplate